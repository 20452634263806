import update from 'immutability-helper';
import { FREESTAR_ENABLED } from 'src/common/constants/experiments/ads';
import createMappedReducer from 'src/common/reducers/utils/createMappedReducer';
import {
  getNewAdUnitId,
  gptFreestarSlotsMap,
  gptSlotNames,
  oldAdUnits,
} from 'src/common/utils/ads/config';
import { FETCH_CONFIG } from '../actions/config';
import { fulfilled } from './utils/asyncActionNameSuffixes';

export const config = createMappedReducer(
  {},
  {
    [fulfilled(FETCH_CONFIG)](state, action) {
      const isFreestarEnabled = action?.payload?.autoupdate?.[FREESTAR_ENABLED];

      // [note] It's a temporal logic, once we know we go with Freestar solution only then we'll optimize an existing ads config which will include the Freestar slots in it, so we'll remove this logic and keep the old one with setting the payload.
      if (isFreestarEnabled && action?.payload?.ads?.adConfig) {
        const { adConfig, targeting } = action.payload.ads;
        const adsPartnerAlias = targeting?.ads_partner_alias;

        const updatedAdConfig = adConfig?.map((adConfigItem) => {
          const { slots, webScreens, formats } = adConfigItem;

          // Changes to the Freestar slot name per slots[item].name
          const updatedSlots = slots?.map((slot) => ({
            ...slot,
            name: gptSlotNames[slot.name]
              ? gptFreestarSlotsMap[gptSlotNames[slot.name]]
              : slot.name,
          }));

          // Replaces to the Freestar slots per webScreen[item].slots[item]
          const updatedWebScreens = webScreens?.map((webScreen) => ({
            ...webScreen,
            slots: webScreen.slots?.map((slot) =>
              gptSlotNames[slot]
                ? gptFreestarSlotsMap[gptSlotNames[slot]]
                : slot,
            ),
          }));

          // Changes to the new ad unit id per formats[item].adUnitId
          const updatedFormats = formats?.map((format) => {
            const { medium, adUnitId, name } = format;
            return {
              ...format,
              adUnitId:
                medium === 'display' && oldAdUnits.includes(adUnitId)
                  ? getNewAdUnitId(adUnitId, adsPartnerAlias, name)
                  : adUnitId,
              // TODO: Check with team if we also need to set it.
              // format.adProvider = 'freestar'.
            };
          });

          return {
            ...adConfigItem,
            slots: updatedSlots,
            webScreens: updatedWebScreens,
            formats: updatedFormats,
          };
        });

        action.payload.ads.adConfig = updatedAdConfig;
      }

      return update(state, {
        $set: action.payload,
      });
    },
  },
);
