import { isType } from '@tunein/web-common';
import update from 'immutability-helper';
import get from 'lodash/get';
import mapKeys from 'lodash/mapKeys';
import omit from 'lodash/omit';
import { playerStatuses } from 'src/common/constants/playerStatuses';
import createMappedReducer from 'src/common/reducers/utils/createMappedReducer';
import { types } from 'src/common/utils/guideItemTypes';
import * as PlayerActions from '../actions/player';
import * as TunerActions from '../actions/tuner';
import { PREROLL_SLOT_NAME } from '../components/ads/constants/displayAds';
import { fulfilled } from './utils/asyncActionNameSuffixes';

const initialState = {
  playerStatus: playerStatuses.idle,
  isTunePrimed: false,
  nowPlaying: {},
  followOptions: {},
  positionInfo: {
    elapsedPercent: 0,
    elapsedSeconds: 0,
    duration: 0,
  },
  userInitiatedSeekCount: 0,
  volume: 100,
  playbackRate: 1.0,
  canChangePlaybackRate: false,
  previousVolume: 0,
  externalHtmlPlayerUrl: null,
  streamEvaluationStart: null,
  tuneRequestStart: null,
  hadPreroll: null,
  canScrub: false,
  showStartEnd: false,
  showVolumeBar: false,
  autoPlayReady: false,
  autoPlayGuideItem: {},
  listenId: null,
  // NOTE: (isBoostFeaturedInPlayer can be temporarily different than isBoostStation, such as during transition states such as loading & outro)
  isBoostFeaturedInPlayer: false, // set by the application layer, to indicate if a user has the boost artwork featured in player
  isBoostStation: false, // NOTE: pulled from the stream of the Tune.ashx response, designates if the stream is a boost stream
  isBoostTooltipOpen: false,
  isIntroInProgress: false,
  isOutroInProgress: false,
  boostGuideId: null,
  isTunerReady: false,
};

function primeTuneForMobileSync(state) {
  return {
    ...state,
    isTunePrimed: true,
  };
}

function handleFeatureBoostInPlayer(state, action) {
  const { isBoostFeaturedInPlayer } = action;

  return {
    ...state,
    isBoostFeaturedInPlayer,
    boostGuideId: isBoostFeaturedInPlayer
      ? state.nowPlaying?.boost?.primary?.guideId
      : null,
  };
}

function handleInitiateIntro(state, action) {
  return {
    ...state,
    isIntroInProgress: true,
    tunedBoostGuideId: action.boostGuideId,
  };
}

function handleInitiateOutro(state, action) {
  return {
    ...state,
    isOutroInProgress: true,
    tunedBoostGuideId: action.boostGuideId,
  };
}

function handleEndIntro(state) {
  return {
    ...state,
    isIntroInProgress: false,
  };
}

function handleEndOutro(state) {
  return {
    ...state,
    isOutroInProgress: false,
  };
}

function handleSetBoostTooltip(state, action) {
  const { isBoostTooltipOpen } = action;

  return {
    ...state,
    isBoostTooltipOpen,
  };
}

function requestTune(state, action) {
  const {
    tunedGuideId,
    parentGuideId,
    nowPlaying,
    guideItemPathname,
    itemToken,
    tuneRequestStart,
  } = action;

  return {
    ...state,
    isTunePrimed: false,
    tunedGuideId,
    parentGuideId,
    nowPlaying,
    guideItemPathname,
    playerStatus: playerStatuses.connecting,
    positionInfo: {
      elapsedPercent: 0,
      elapsedSeconds: 0,
      duration: 0,
    },
    itemToken,
    tuneRequestStart,
  };
}

function handleLoad(state) {
  const { tunedGuideId } = state;

  return {
    ...state,
    playerStatus: playerStatuses.connecting,
    canScrub:
      isType(tunedGuideId, types.topic) ||
      isType(tunedGuideId, types.audioClip),
    showStartEnd: true,
    autoPlayReady: false,
  };
}

function tunerPlaying(state, action) {
  const { isBoostStation, isBoostTransition } = action?.currentStream || {};

  return {
    ...state,
    isBoostStation,
    playerStatus: playerStatuses.playing,
    streamEvaluationStart: null,
    hadPreroll: null,
    ...(!isBoostTransition && { tuneRequestStart: null }),
  };
}

function handleMediaAdLoaded(state, action) {
  return {
    ...state,
    isMediaAdLoaded: true,
    canScrub: true,
    showStartEnd: true,
    hadPreroll: action?.slotName === PREROLL_SLOT_NAME,
  };
}

function handleMediaAdPlaying(state) {
  return {
    ...state,
    playerStatus: playerStatuses.playing,
  };
}

function handleMediaAdEnded(state) {
  const { tunedGuideId } = state;
  return {
    ...state,
    positionInfo: initialState.positionInfo,
    isMediaAdLoaded: false,
    canScrub:
      isType(tunedGuideId, types.topic) ||
      isType(tunedGuideId, types.audioClip),
    showStartEnd: true,
  };
}

function tunerPaused(state) {
  return {
    ...state,
    playerStatus: playerStatuses.paused,
  };
}

function tunerStopped(state) {
  return {
    ...state,
    playerStatus: playerStatuses.stopped,
  };
}

function tunerSeek(state, action) {
  const { duration } = state.positionInfo;
  const { positionPercent, isDiscordSyncRequest = false } = action;

  return {
    ...state,
    positionInfo: {
      ...state.positionInfo,
      duration,
      elapsedPercent: positionPercent,
      elapsedSeconds: positionPercent * duration * 0.01,
    },
    userInitiatedSeekCount: isDiscordSyncRequest
      ? state.userInitiatedSeekCount
      : state.userInitiatedSeekCount + 1,
  };
}

function tunerSetVolume(state, action) {
  return {
    ...state,
    previousVolume: state.volume,
    volume: action.value,
  };
}

function setShowVolumeBar(state, action) {
  return {
    ...state,
    showVolumeBar: action.value,
  };
}

function setHasAdBlocker(state, action) {
  return {
    ...state,
    hasAdBlocker: action.hasAdBlocker,
  };
}

function handleStartStreamEvaluation(state, action) {
  return {
    ...state,
    streamEvaluationStart: action.streamEvaluationStart,
  };
}

function tunerConnecting(state, action) {
  return {
    ...state,
    tunedGuideId: action.tunedGuideId,
    tunedBoostGuideId: action.tunedBoostGuideId,
    playerStatus: playerStatuses.connecting,
  };
}

function tunerLoading(state) {
  return {
    ...state,
    playerStatus: playerStatuses.connecting,
  };
}

function handleMeta(state, { meta = {} }) {
  const { title, image } = meta;
  const nowPlaying = {};
  const stationImage = state?.nowPlaying?.stationImage;
  if (title) {
    nowPlaying.title = title;
  }
  // If available, use album image
  nowPlaying.image = image || stationImage;
  return update(state, {
    nowPlaying: {
      $merge: nowPlaying,
    },
  });
}

function handlePlayerSelected(state, { playerName }) {
  if (!state.playerNameList || state.playerNameList?.includes(playerName)) {
    return state;
  }

  return update(state, {
    playerNameList: { $push: [playerName] },
  });
}

function handleClearPlayerNameList(state) {
  return {
    ...state,
    playerNameList: [],
  };
}

function handleSetListenId(state, action) {
  return {
    ...state,
    listenId: action.listenId,
  };
}

function setTunerReady(state) {
  return {
    ...state,
    isTunerReady: true,
  };
}

// TUNE-17294: ticket for below to-do
// TODO: we need to clean up this reducer and use $set instead of $merge for NP. We'll have to:
//  1. Make the action payload consistent upstream
//  2. Track `share` separately if we need to persist that data across NP updates (if so, this should be extracted
//    into its own namespace in WAC)
//  3. Remove `stationImage` as it seems redundant (and update any code that relies on it)
//  4. Move followOptions transform/extraction to WAC
function handleNowPlayingChanged(state, action) {
  // NOTE: This action obj can look slightly different when coming from web-tuner vs. web-api-client
  const nowPlaying =
    get(action, 'nowPlaying') || get(action, 'payload.nowPlaying');
  // Hack to remove prior boost metadata due to using $merge. See to-do above for fix proposal.
  const previousNowPlaying = omit(state.nowPlaying, ['boost', 'primary']);

  // to merge share
  const mergedShare = update(previousNowPlaying.share || {}, {
    $merge: nowPlaying.share,
  });

  // to merge description
  const mergedNowPlaying = update(previousNowPlaying, {
    $merge: nowPlaying,
    image: { $set: nowPlaying.image },
    stationImage: { $set: nowPlaying.image },
    share: { $set: mergedShare },
    rejectReasonKey: { $set: nowPlaying.rejectReasonKey },
  });

  const followOptions = mapKeys(
    nowPlaying.followOptions,
    ({ guideId }) => guideId,
  );

  return update(state, {
    followOptions: { $set: followOptions },
    nowPlaying: { $set: omit(mergedNowPlaying, 'followOptions') },
  });
}

function tunerPositionChanged(state, action) {
  const { isMediaAdLoaded, canScrub, showStartEnd } = state;
  const { positionInfo = {}, isMobile } = action;
  const { isLiveSeekStream } = positionInfo;

  return {
    ...state,
    positionInfo,
    canScrub: isMediaAdLoaded || (isLiveSeekStream && !isMobile) || canScrub,
    showStartEnd: isMediaAdLoaded || showStartEnd,
  };
}

function mediaEnded(state) {
  return {
    ...state,
    playerStatus: playerStatuses.stopped,
    positionInfo: {
      elapsedPercent: 0,
      elapsedSeconds: 0,
    },
  };
}

function tunerWaiting(state) {
  return {
    ...state,
    playerStatus: playerStatuses.connecting,
  };
}

function setTuneContextLabel(state, action) {
  return {
    ...state,
    tuneContextLabel: action.label,
  };
}

function tunerFailed(state) {
  return {
    ...state,
    playerStatus: playerStatuses.failed,
    playerNameList: [],
    streamEvaluationStart: null,
    tuneRequestStart: null,
    hadPreroll: null,
  };
}

function handleHtmlStream(state, action) {
  return {
    ...state,
    playerStatus: state.isDiscord
      ? playerStatuses.failed
      : playerStatuses.popout,
    externalHtmlPlayerUrl: action.externalHtmlPlayerUrl,
  };
}

function nextTune(state, action) {
  return {
    ...state,
    playerNameList: [],
    itemToken: action.itemToken,
    guideItemPathname: action.guideItemPathname || state.guideItemPathname,
  };
}

function setPopoutPlayerStatus(state) {
  return update(state, {
    playerStatus: { $set: playerStatuses.popout },
    externalHtmlPlayerUrl: { $set: null },
  });
}

function handlePlayerRateChange(state, { rate, canChangePlaybackRate }) {
  return update(state, {
    playbackRate: { $set: rate },
    canChangePlaybackRate: { $set: canChangePlaybackRate },
  });
}

function handleAutoPlay(state, action) {
  const guideItem = get(action, 'meta.guideItem', {});
  const guideItemPathname = get(action, 'meta.guideItemPathname');

  return update(state, {
    isTunePrimed: { $set: false },
    tunedGuideId: { $set: guideItem.guideId },
    playerStatus: { $set: playerStatuses.idle },
    positionInfo: {
      elapsedPercent: { $set: 0 },
      elapsedSeconds: { $set: 0 },
      duration: { $set: 0 },
    },
    autoPlayGuideItem: { $set: guideItem },
    autoPlayReady: { $set: true },
    guideItemPathname: { $set: guideItemPathname },
  });
}

function setGuideItemPathname(state, action) {
  return update(state, {
    guideItemPathname: { $set: action.guideItemPathname },
  });
}

export const player = createMappedReducer(initialState, {
  [fulfilled(PlayerActions.FETCH_NOW_PLAYING)]: handleNowPlayingChanged,
  [PlayerActions.PRE_TUNE]: primeTuneForMobileSync,
  [PlayerActions.REQUEST_TUNE]: requestTune,
  [PlayerActions.HANDLE_LOAD]: handleLoad,
  [PlayerActions.MEDIA_AD_LOADED]: handleMediaAdLoaded,
  [PlayerActions.MEDIA_AD_PLAYING]: handleMediaAdPlaying,
  [PlayerActions.MEDIA_AD_ERROR]: handleMediaAdEnded,
  [PlayerActions.MEDIA_AD_ENDED]: handleMediaAdEnded,
  [PlayerActions.PAUSE]: tunerPaused,
  [PlayerActions.STOP]: tunerStopped,
  [PlayerActions.SEEK]: tunerSeek,
  [PlayerActions.SET_VOLUME]: tunerSetVolume,
  [PlayerActions.SET_HAS_ADBLOCKER]: setHasAdBlocker,
  [PlayerActions.SET_SHOW_VOLUME_BAR]: setShowVolumeBar,
  [PlayerActions.SET_POPOUT_PLAYER_STATUS]: setPopoutPlayerStatus,
  [PlayerActions.AUTOPLAY_READY]: handleAutoPlay,
  [PlayerActions.WAITING_FOR_GDPR]: tunerWaiting,
  [PlayerActions.SET_TUNE_CONTEXT_LABEL]: setTuneContextLabel,
  [PlayerActions.SET_GUIDE_ITEM_PATHNAME]: setGuideItemPathname,
  [PlayerActions.FEATURE_BOOST_IN_PLAYER]: handleFeatureBoostInPlayer,
  [PlayerActions.SET_BOOST_TOOLTIP]: handleSetBoostTooltip,
  [PlayerActions.END_INTRO]: handleEndIntro,
  [PlayerActions.END_OUTRO]: handleEndOutro,
  [TunerActions.INITIATE_INTRO]: handleInitiateIntro,
  [TunerActions.INITIATE_OUTRO]: handleInitiateOutro,
  [TunerActions.CHANGE_PLAYBACK_RATE]: handlePlayerRateChange,
  [TunerActions.START_STREAM_EVALUATION]: handleStartStreamEvaluation,
  [TunerActions.PLAYING]: tunerPlaying,
  [TunerActions.CONNECTING]: tunerConnecting,
  [TunerActions.LOADING]: tunerLoading,
  [TunerActions.NOW_PLAYING_CHANGED]: handleNowPlayingChanged,
  [TunerActions.POSITION_CHANGED]: tunerPositionChanged,
  [TunerActions.MEDIA_ENDED]: mediaEnded,
  [TunerActions.WAITING]: tunerWaiting,
  [TunerActions.FAILED]: tunerFailed,
  [TunerActions.HANDLE_HTML_STREAM]: handleHtmlStream,
  [TunerActions.NEXT_TUNE]: nextTune,
  [TunerActions.HANDLE_META]: handleMeta,
  [TunerActions.PLAYER_SELECTED]: handlePlayerSelected,
  [TunerActions.CLEAR_PLAYER_NAME_LIST]: handleClearPlayerNameList,
  [TunerActions.SET_LISTEN_ID]: handleSetListenId,
  [TunerActions.TUNER_READY]: setTunerReady,
});
